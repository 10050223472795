// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useGetResourceLabel, useRecordContext, RecordContextProvider } from 'react-admin';

import { Team } from '@/types';
import { MembershipList } from './membership';
import TeamShow from './TeamShow';

type TeamListAsideProps = object;

const TeamListAside: FC<TeamListAsideProps> = () => {
    const getResourceLabel = useGetResourceLabel();
    const record = useRecordContext<Team>();

    return (
        <Box
            margin={2}
            maxWidth={700}
        >
            <TeamShow />
            <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
            <Card>
                <CardContent>
                    <Typography
                        variant='subtitle1'
                        color='textSecondary'
                    >
                        {getResourceLabel('memberships')}
                    </Typography>
                    <RecordContextProvider value={record}>
                        <MembershipList />
                    </RecordContextProvider>
                </CardContent>
            </Card>
        </Box>
    );
};

export default TeamListAside;
