// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC } from 'react';
import Divider from '@mui/material/Divider';
import {
    FunctionField, SimpleShowLayout, useRecordContext, RecordContextProvider,
} from 'react-admin';

import { ProviderModelConfigWithEntity } from '@/types';
import { ProviderField } from '@/provider';
import { ModelField, ModelParametersInfo } from '@/provider/model';

type ProviderModelConfigInfoCardProps = object;

const ProviderModelConfigInfoCard: FC<ProviderModelConfigInfoCardProps> = () => {
    const providerModelConfig = useRecordContext<ProviderModelConfigWithEntity>();

    if (!providerModelConfig) {
        return null;
    }

    return (
        <SimpleShowLayout
            spacing={1}
            sx={{ pl: 0 }}
            divider={<Divider />}
        >
            <FunctionField
                source='related_model.provider_name'
                render={() => (
                    <ProviderField providerEntity={providerModelConfig.entity.provider} />
                )}
            />
            <FunctionField
                source='related_model.model_name'
                render={() => (
                    <RecordContextProvider value={providerModelConfig.entity}>
                        <ModelField
                            properties={
                                Object.keys(providerModelConfig.entity.model_properties) as
                                    Array<keyof typeof providerModelConfig.entity.model_properties>
                            }
                        />
                    </RecordContextProvider>
                )}
            />
            {
                providerModelConfig.entity.parameter_rules && (
                    <FunctionField
                        source='related_model.config.parameters'
                        render={() => providerModelConfig.entity.parameter_rules && (
                            <ModelParametersInfo
                                source='related_model.config.parameters'
                                parameterRules={providerModelConfig.entity.parameter_rules}
                                parameterValues={providerModelConfig.config.parameters}
                            />
                        )}
                    />
                )
            }
        </SimpleShowLayout>
    );
}

export default ProviderModelConfigInfoCard;
