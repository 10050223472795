// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import { RaRecord } from 'react-admin';

import { Assistant } from './assistant';
import { ProviderModelConfigWithEntity } from './model';
import { Prompt } from './prompt';

export interface SessionType extends RaRecord {
    name: string;
}

export interface SessionDailyUsage {
    day: string;
    total_messages: number;
    total_price: number;
}

export interface SessionUsage {
    total_messages: number;
    total_input_tokens: number;
    total_output_tokens: number;
    total_price: number;
    total_input_price: number;
    total_output_price: number;
    currency: string;
    per_day: SessionDailyUsage[];
}

export interface Session extends RaRecord {
    name: string;
    type: string;
    mode: string;
    related_model?: ProviderModelConfigWithEntity;
    related_prompt?: Prompt | undefined;
    related_assistant?: Assistant | undefined;
    created_at?: string;
    updated_at?: string;
    usage?: SessionUsage;
}

export enum SessionFloatActionKey {
    SETTINGS = 'settings',
    INFO = 'info',
    USAGE = 'usage',
    NEW = 'new',
};
