// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC } from 'react';
import {
    EditBase, Form, useTranslate, useLocale,
    useRecordContext, RecordContextProvider, useNotify,
} from 'react-admin';

import { ProviderConfiguration, Provider } from '@/types';
import { ButtonWithDialog } from '@/common';

import ProviderSetupForm from './ProviderSetupForm';
import ProviderFormActions from './ProviderFormActions';
import ProviderHelp from './ProviderHelp';

type ProviderEditButtonProps = object;

const ProviderEditButton: FC<ProviderEditButtonProps> = () => {
    const translate = useTranslate();
    const locale = useLocale();
    const notify = useNotify();
    const providerConfiguration = useRecordContext<ProviderConfiguration>();

    if (!providerConfiguration) {
        return null;
    }

    return (
        <EditBase<Provider>
            resource='providers'
            id={providerConfiguration.user_configuration.provider as string}
            mutationMode='pessimistic'
            redirect={false}
            queryOptions={{
                onError: () => {
                    notify('ra.notification.item_doesnt_exist', {
                        type: 'error',
                    });
                },
            }}
        >
            <Form>
                <ButtonWithDialog
                    label='action.setup'
                    sx={{
                        width: '100%',
                    }}
                    dialog={{
                        title: translate('action.setup_name', { name: providerConfiguration.label[locale] }),
                        maxWidth: 'sm',
                        fullWidth: true,
                        disableBackdropClick: true,
                        ContentProps: {
                            dividers: true,
                        },
                        dialogContent: (
                            <ProviderSetupForm
                                credentialFormSchemas={
                                    providerConfiguration.provider_credential_schema.credential_form_schemas
                                }
                            />
                        ),
                        dialogAction: (onClose) => (
                            <>
                                <RecordContextProvider value={providerConfiguration}>
                                    <ProviderHelp />
                                </RecordContextProvider>
                                <ProviderFormActions
                                    onClose={() => onClose && onClose({}, 'escapeKeyDown')}
                                />
                            </>
                        )
                    }}
                />
            </Form>
        </EditBase>
    );
};

export default ProviderEditButton;
