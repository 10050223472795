// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC } from 'react';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import {
    useRecordContext, DateField, useTranslate,
    Labeled, RecordContextProvider, ResourceContextProvider,
} from 'react-admin';
import { styled } from '@mui/material/styles';

import { Session } from '@/types';
import { UserField } from '@/user';
import { ProviderModelConfigInfoCard } from '@/provider/model';
import { PromptInfo } from '@/prompt/show';
import { AssistantInfo } from '@/assistant/show';
import SessionName from './SessionName';

const StyledCard = styled(Card, {
    name: 'PromptInfoCard',
    slot: 'root',
})(() => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
}));

type SessionInfoCardProps = object;

const SessionInfoCard: FC<SessionInfoCardProps> = () => {
    const session = useRecordContext<Session>();
    const translate = useTranslate();

    if (!session) {
        return null;
    }

    return (
        <StyledCard>
            <CardHeader
                title={<SessionName />}
                subheader={(
                    <Stack
                        direction='row'
                        spacing={2}
                        divider={<Divider orientation='vertical' flexItem />}
                        m={2}
                        ml={0}
                    >
                        <Chip
                            title={translate('resources.sessions.fields.type')}
                            label={session.type.toUpperCase()}
                            color='primary'
                            variant='outlined'
                            size='small'
                        />
                        <Labeled source='owner.username'>
                            <UserField user={session.owner} />
                        </Labeled>
                        <Labeled source='created_date'>
                            <DateField source='created_date' showTime />
                        </Labeled>
                    </Stack>
                )}
            />
            <CardContent>
                {
                    session.type === 'llm' && (
                        <RecordContextProvider value={session.related_model}>
                            <ProviderModelConfigInfoCard />
                        </RecordContextProvider>
                    )
                }
                {
                    session.type === 'prompt' && (
                        <ResourceContextProvider value='prompts'>
                            <RecordContextProvider value={session.related_prompt}>
                                <PromptInfo />
                            </RecordContextProvider>
                        </ResourceContextProvider>
                    )
                }
                {
                    session.type === 'assistant' && (
                        <ResourceContextProvider value='assistants'>
                            <RecordContextProvider value={session.related_assistant}>
                                <AssistantInfo />
                            </RecordContextProvider>
                        </ResourceContextProvider>
                    )
                }
                <Divider />
            </CardContent>
        </StyledCard>
    );
};

export default SessionInfoCard;
