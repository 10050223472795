// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC, useContext, useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import { useTranslate, useRecordContext } from 'react-admin';
import Uploady, { UploadyProps } from '@rpldy/uploady';

import { SessionContext } from '@/context';
import { Session } from '@/types';
import { useChatHandler } from '@/hook';
import { ResourceURL } from '@/utils';
import { createOptions } from '@/authProvider';

import { TextareaAutosize } from '@/common';
import SendButton from './SendButton';
import AttachButton from './AttachButton';
import Attachment from './Attachment';
import PromptSelection, { PromptSelectionButton } from './PromptSelection';

type ChatInputProps = UploadyProps

const ChatInput: FC<ChatInputProps> = ({
    ...props
}) => {
    const session = useRecordContext<Session>();

    const translate = useTranslate();

    const [, setIsTyping] = useState<boolean>(false)

    const {
        userInput, setUserInput,
    } = useContext(SessionContext);
    const { chatInputRef } = useChatHandler();

    const handleInputChange = (value: string): void => {
        setUserInput(value);
    }

    const url = ResourceURL(`/sessions/${session?.id}/upload`)
    const options = createOptions(url);
    if (!options.user || !options.headers) {
        return null;
    }
    options.headers.set('authorization', options.user.token);
    const headersRecord: Record<string, string> = {};
    options.headers.forEach((value, key) => {
        headersRecord[key] = value;
    });

    return (
        <Uploady
            {...props}
            multiple={false}
            autoUpload={false}
            clearPendingOnAdd
            accept='text/plain,text/html,application/pdf'
            destination={{
                url,
                headers: headersRecord,
            }}
        >
            <PromptSelection />
            <Attachment />
            <TextareaAutosize
                formControlProps={{
                    disabled: !session,
                    sx: { width: '100%' },
                }}
                inputProps={{
                    ref: chatInputRef,
                    minRows: 1,
                    maxRows: 4,
                    placeholder: translate('label.chat.input.placeholder'),
                    value: userInput,
                    multiline: true,
                    onCompositionStart: () => setIsTyping(true),
                    onCompositionEnd: () => setIsTyping(false),
                    onChange: (e) => handleInputChange(e.target.value),
                    startAdornment: (
                        <InputAdornment position='start' sx={{marginTop: '0 !important'}}>
                            <PromptSelectionButton />
                            <AttachButton />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position='end'>
                            <SendButton />
                        </InputAdornment>
                    )
                }}
            />
        </Uploady>
    );
};

export default ChatInput;
