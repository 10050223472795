// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Tooltip from '@mui/material/Tooltip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import {
    SelectInput, BooleanInput,
    required, useLocale, minValue, maxValue,
    TextInput, NumberInput,
} from 'react-admin';

import {
    ParameterType, ParameterRule,
    StringParameterRule, NumberParameterRule,
    ObjectParameterRule,
} from '@/types';
import {
    SliderInput, TextareaAutosize, StackedNodes, ControlledAccordion
} from '@/common';

const HelpIcon = ({ helpText }: any): JSX.Element => (
    <Tooltip title={helpText}>
        <InfoOutlinedIcon fontSize='small' />
    </Tooltip>
);

export interface ConfigurableInputProps {
    parameterRule: ParameterRule;
    defaultValue?: any;
    onChange?: (value: any, configuration: ParameterRule) => void;
}

const ConfigurableInput: FC<ConfigurableInputProps> = ({
    parameterRule,
    defaultValue,
    onChange,
}) => {
    const locale = useLocale();

    const defaultParams = (): any => ({
        source: parameterRule.name,
        label: parameterRule.label[locale],
        variant: 'outlined',
        fullWidth: true,
        onChange: onChange ? (e: React.ChangeEvent<HTMLInputElement>) => (
            onChange(e.target.value, parameterRule)
        ) : undefined,
        InputProps:
            parameterRule.help ? ({
                startAdornment: (
                    parameterRule.type === ParameterType.STRING &&
                    (parameterRule as StringParameterRule).options &&
                    (parameterRule as StringParameterRule).options.length > 0
                ) ? (
                        <InputAdornment position='start'>
                            <HelpIcon helpText={parameterRule.help[locale]} />
                        </InputAdornment>
                    ) : undefined,
                endAdornment: (
                    parameterRule.type !== ParameterType.STRING ||
                    (parameterRule as StringParameterRule).options === undefined
                ) ? (
                        <InputAdornment position='end'>
                            <HelpIcon helpText={parameterRule.help[locale]} />
                        </InputAdornment>
                    ) : undefined,
            }) : undefined,
    });

    const validators: any[] = parameterRule.required ? [required()] : [];

    switch(parameterRule.type) {
        case ParameterType.FLOAT:
        case ParameterType.INT: {
            const numberParameterRule = parameterRule as NumberParameterRule;
            if (numberParameterRule.min) {
                validators.push(minValue(numberParameterRule.min));
            }
            if (numberParameterRule.max) {
                validators.push(maxValue(numberParameterRule.max));
            }
            const step = numberParameterRule.precision ? 1 / 10**numberParameterRule.precision : 1;

            if (parameterRule.type === ParameterType.INT) {
                return (
                    <NumberInput
                        validate={validators}
                        defaultValue={defaultValue}
                        min={numberParameterRule.min ? numberParameterRule.min : undefined}
                        max={numberParameterRule.max ? numberParameterRule.max : undefined}
                        step={step}
                        {...defaultParams()}

                    />
                );
            }

            return (
                <SliderInput
                    validate={validators}
                    defaultValue={defaultValue}
                    min={numberParameterRule.min}
                    max={numberParameterRule.max}
                    step={step}
                    margin='dense'
                    {...defaultParams()}
                />
            );
        }
        case ParameterType.BOOLEAN: {
            return (
                <BooleanInput
                    validate={validators}
                    options={{
                        checkedIcon: <CheckCircleIcon />,
                    }}
                    defaultValue={defaultValue}
                    {...defaultParams()}
                />
            );
        }
        case ParameterType.SECRET:
        case ParameterType.STRING: {
            const stringParameterRule = parameterRule as StringParameterRule;
            if (stringParameterRule.options && stringParameterRule.options.length > 0) {
                const { onChange: providedOnChange, ...rest } = defaultParams();
                return (
                    <>
                        <SelectInput
                            choices={
                                stringParameterRule.options.map(
                                    (item) => ({
                                        id: item.name,
                                        name: item.label[locale],
                                        disabled: item.disabled,
                                    }),
                                )
                            }
                            defaultValue={defaultValue}
                            validate={validators}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                if (providedOnChange) {
                                    providedOnChange(e.target.value, parameterRule);
                                }
                                stringParameterRule.options.forEach((option) => {
                                    const div = document.getElementById(option.name);
                                    if (div) {
                                        if (option.name === e.target.value) {
                                            div.style.display = 'block';
                                        } else {
                                            div.style.display = 'none';
                                        }
                                    }
                                });
                            }}
                            {...rest}
                        />
                        {
                            stringParameterRule.options.map(
                                (rule: ParameterRule, index: React.Key | null | undefined) => !rule.disabled && (
                                    <Box
                                        id={rule.name}
                                        display={defaultValue === rule.name ? 'block' : 'none'}
                                    >
                                        <ConfigurableInput
                                            key={index}
                                            parameterRule={rule}
                                            defaultValue={rule.default}
                                        />
                                    </Box>
                                ))
                        }
                    </>
                );
            }
            return (
                <TextInput
                    defaultValue={defaultValue}
                    validate={validators}
                    {...defaultParams()}
                    inputProps={{
                        type: stringParameterRule.type === ParameterType.SECRET ? 'password' : 'text',
                    }}
                />
            );
        }
        case ParameterType.TEXT: {
            return (
                <TextareaAutosize />
            );
        }
        case ParameterType.OBJECT: {
            const objectParameterRule = parameterRule as ObjectParameterRule;

            if (!objectParameterRule.parameters || objectParameterRule.parameters.length === 0) {
                return null;
            }
            const parameters = objectParameterRule.parameters.map((rule) => ({
                ...rule,
                name: `${parameterRule.name}.${rule.name}`,
            }));

            const basicRules = parameters.filter((rule) => !rule.advanced);
            const advancedRules = parameters.filter((rule) => rule.advanced);

            return (
                <Card>
                    <CardHeader
                        title={objectParameterRule.label[locale]}
                        titleTypographyProps={{
                            variant: 'subtitle1',
                        }}
                        sx={{ p: 1 }}
                    />
                    <CardContent
                        sx={{ p: 1 }}
                    >
                        {
                            basicRules.length > 0 && (
                                <StackedNodes>
                                    {
                                        basicRules.map((rule: ParameterRule, index: React.Key | null | undefined) => (
                                            <ConfigurableInput
                                                key={index}
                                                parameterRule={rule}
                                                defaultValue={rule.default}
                                                onChange={onChange}
                                            />
                                        ))
                                    }
                                </StackedNodes>
                            )
                        }
                        {
                            advancedRules.length > 0 && (
                                <ControlledAccordion
                                    panels={['advanced']}
                                    titles={['Advanced']}
                                >
                                    <StackedNodes>
                                        {
                                            advancedRules.map(
                                                (rule: ParameterRule, index: React.Key | null | undefined) => (
                                                    <ConfigurableInput
                                                        key={index}
                                                        parameterRule={rule}
                                                        defaultValue={rule.default}
                                                        onChange={onChange}
                                                    />
                                                ))
                                        }
                                    </StackedNodes>
                                </ControlledAccordion>
                            )
                        }
                    </CardContent>
                </Card>
            );
        }
        default:
            return null;
    }
};

export default ConfigurableInput;
