// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, {
    useState, FC, ReactNode, useMemo,
} from 'react';
import { AssistantContext } from '@/context';

interface AssistantStateProps {
    children: ReactNode,
};

export const AssistantState: FC<AssistantStateProps> = ({ children }) => {
    const [activeStep, setActiveStep] = useState<number>(0);
    const [mode, setMode] = useState<'create' | 'edit'>('create');
    const [remainingFiles, setRemainingFiles] = useState<number>(0);

    const contextValue = useMemo(() => ({
        activeStep,
        setActiveStep,
        mode,
        setMode,
        remainingFiles,
        setRemainingFiles,
    }), [activeStep, mode, remainingFiles]);

    return (
        <AssistantContext.Provider value={contextValue}>
            {children}
        </AssistantContext.Provider>
    );
};
