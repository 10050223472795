// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC, useContext } from 'react';
import {
    ListBase, Datagrid, TextField,
    ReferenceField, ExportButton, CreateButton,
    BulkDeleteWithConfirmButton, SearchInput,
    SelectInput, BooleanInput,
} from 'react-admin';

import { PromptType, PromptStatus, TeamRole } from '@/types';
import { GlobalContext } from '@/context';
import { ListGridSwitcher } from '@/common';
import PromptInfoCard from './PromptInfoCard';
import { GroupField, GroupSelectInput } from '@/group';
import { getChoicesFromEnum } from '@/utils';

const PromptListActions = [
    <CreateButton key='create' />,
    <ExportButton key='export' />,
];

const PromptFilters = [
    <SearchInput
        source='q'
        variant='standard'
    />,
    <GroupSelectInput
        source='group__id'
        reference='prompt-groups'
        showCreateOption={false}
        validate={undefined}
    />,
    <SelectInput
        source='type'
        choices={getChoicesFromEnum(PromptType)}
        variant='standard'
    />,
    <SelectInput
        source='status'
        choices={getChoicesFromEnum(PromptStatus)}
        variant='standard'
    />,
    <BooleanInput
        source='is_pinned'
        variant='standard'
    />
];

const PromptBulkActionButtons: FC = () => (
    <BulkDeleteWithConfirmButton mutationMode='pessimistic' />
);

type PromptListProps = object

const PromptList: FC<PromptListProps> = () => {
    const { currentMembership } = useContext(GlobalContext);

    const isOwnerOrAdmin = currentMembership?.role === TeamRole.OWNER || currentMembership?.role === TeamRole.ADMIN;

    return (
        <ListBase perPage={12}>
            <ListGridSwitcher
                actions={PromptListActions}
                filters={PromptFilters}
                ItemComponent={<PromptInfoCard />}
            >
                <Datagrid
                    bulkActionButtons={
                        isOwnerOrAdmin ? (
                            <PromptBulkActionButtons />
                        ) : false
                    }
                    rowClick={isOwnerOrAdmin ? 'edit' : false}
                >
                    <TextField source='name' />
                    <ReferenceField
                        source='group.id'
                        reference='prompt-groups'
                        sortBy='group__name'
                    >
                        <GroupField />
                    </ReferenceField>
                    <TextField source='type' />
                    <TextField source='status' />
                </Datagrid>
            </ListGridSwitcher>
        </ListBase>
    );
};

export default PromptList;
