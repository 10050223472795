// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC, useState } from 'react';
import Grid from '@mui/material/Grid2';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import {
    Datagrid, List, TextField, DateField, Identifier,
    RowClickFunction, useTranslate,
    useCreatePath, Button, useRedirect, ShowBase, useRecordContext,
} from 'react-admin';

import { Session } from '@/types';
import { SessionState } from '@/state';
import { Dialog } from '@/common';

import { SessionInfoCard, SessionUsageCard } from '@/session/info';

interface ShowButtonProps {
    label?: string;
}

const ShowButton: FC<ShowButtonProps> = ({
    label,
}) => {
    const record = useRecordContext<Session>();
    const redirect = useRedirect();
    const createPath = useCreatePath();
    const translate = useTranslate();

    if (!record) {
        return null;
    }

    return (
        <Button
            onClick={() => redirect(createPath({
                resource: 'sessions',
                id: record.id,
                type: 'show'
            }))}
            startIcon={<RemoveRedEyeIcon />}
            label={label ? translate(label) : ''}
            title={translate('label.view')}
        />
    );
}

type SessionListProps = object;

const SessionList : FC<SessionListProps> = () => {
    const [selectedSessionId, setSelectedSessionId] = useState<Identifier | undefined>();
    const [open, setOpen] = useState<boolean>(false);

    const rowClick: RowClickFunction = (id) => {
        setSelectedSessionId(id);
        setOpen(true);
        return false;
    };

    const onClose = (reason: 'backdropClick' | 'escapeKeyDown'): void => {
        if (reason === 'backdropClick') {
            return;
        }
        setOpen(false);
    };

    return (
        <>
            <List
                sort={{ field: 'created_date', order: 'DESC' }}
            >
                <Datagrid
                    rowClick={rowClick}
                >
                    <TextField source='name' />
                    <TextField source='type' />
                    <TextField source='owner.username' />
                    <DateField source='created_date' showTime />
                    <ShowButton />
                </Datagrid>
            </List>
            {
                selectedSessionId && (
                    <ShowBase
                        resource='sessions'
                        id={selectedSessionId}
                    >
                        <Dialog
                            onClose={(event, reason) => onClose(reason)}
                            open={open}
                            title='Session Info'
                            dialogContent={(
                                <SessionState>
                                    <Grid
                                        container
                                        spacing={2}
                                    >
                                        <Grid
                                            size={{
                                                xs: 12, sm: 12, md: 12, lg: 6
                                            }}
                                        >
                                            <SessionInfoCard />
                                        </Grid>
                                        <Grid
                                            size={{
                                                xs: 12, sm: 12, md: 12, lg: 6
                                            }}
                                        >
                                            <SessionUsageCard />
                                        </Grid>
                                    </Grid>
                                </SessionState>
                            )}
                            dialogAction={() => (
                                <ShowButton label='label.view' />
                            )}
                            maxWidth='lg'
                            disableEscapeKeyDown
                        />
                    </ShowBase>
                )
            }
        </>
    );
};

export default SessionList;
