// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, {
    useState, FC, useEffect, ReactNode, useMemo,
} from 'react';
import { useAuthState } from 'react-admin';
// import cloneDeep from 'lodash/cloneDeep';

import { dataProvider } from '@/dataProvider';
import {
    VectorStoreEntity, CollectionConfiguration,
} from '@/types';
import { CollectionContext } from '@/context';
import { prefixRuleName } from '@/utils';

interface CollectionStateProps {
    children: ReactNode,
};

export const CollectionState: FC<CollectionStateProps> = ({ children }) => {
    const { authenticated } = useAuthState();

    const [configuration, setConfiguration] = useState<CollectionConfiguration | undefined>(undefined);
    const [activeStep, setActiveStep] = useState<number>(0);
    const [mode, setMode] = useState<'create' | 'edit'>('create');
    const [remainingFiles, setRemainingFiles] = useState<number>(0);
    const [selectedVectorStore, setSelectedVectorStore] = useState<VectorStoreEntity | undefined>(undefined);
    const setVectorStore = (
        vectorStore: VectorStoreEntity | undefined
    ): void => {
        if (!vectorStore) {
            setSelectedVectorStore(undefined);
            return;
        }
        setSelectedVectorStore(vectorStore);
        // ToDo: Fix this
        // setSelectedVectorStore({
        //     ...deepCopy,
        //     parameter_rules: prefixRuleName(
        //         'vector_store_config',
        //         (deepCopy as VectorStoreEntity).parameter_rules
        //     )
        // });
    };

    const fetchStartingData = async (): Promise<void> => {
        const { data: config } = await dataProvider.getConfiguration('collections', {});
        setConfiguration({
            ...config,
            parameter_rules: prefixRuleName(
                'chunk_config',
                (config as CollectionConfiguration).parameter_rules
            )
        });
    };

    useEffect(() => {
        (async () => {
            if (!authenticated) return;
            await fetchStartingData();
        })();
    }, [authenticated]);

    const contextValue = useMemo(() => ({
        configuration,
        activeStep,
        setActiveStep,
        mode,
        setMode,
        selectedVectorStore,
        setVectorStore,
        remainingFiles,
        setRemainingFiles,
    }), [configuration, activeStep, mode, selectedVectorStore, remainingFiles]);

    return (
        <CollectionContext.Provider
            value={contextValue}
        >
            {children}
        </CollectionContext.Provider>
    );
};
