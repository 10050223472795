// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC, useContext } from 'react';
import {
    ListBase, Datagrid, TextField,
    ReferenceField, ExportButton, CreateButton,
    BulkDeleteWithConfirmButton, SearchInput,
    SelectInput, BooleanInput,
} from 'react-admin';

import { AssistantStatus, TeamRole } from '@/types';
import { GlobalContext } from '@/context';
import { ListGridSwitcher } from '@/common';
import AssistantInfoCard from './AssistantInfoCard';
import { GroupField, GroupSelectInput } from '@/group';
import { getChoicesFromEnum } from '@/utils';

const AssistantListActions = [
    <CreateButton key='create' />,
    <ExportButton key='export' />,
];

const AssistantFilters = [
    <SearchInput
        source='q'
        variant='standard'
    />,
    <GroupSelectInput
        source='group__id'
        reference='assistant-groups'
        showCreateOption={false}
        validate={undefined}
    />,
    <SelectInput
        source='status'
        choices={getChoicesFromEnum(AssistantStatus)}
        variant='standard'
    />,
    <BooleanInput
        source='is_pinned'
        variant='standard'
    />
];

const AssistantBulkActionButtons: FC = () => (
    <BulkDeleteWithConfirmButton mutationMode='pessimistic' />
);

type AssistantListProps = object

const AssistantList: FC<AssistantListProps> = () => {
    const { currentMembership } = useContext(GlobalContext);

    const isOwnerOrAdmin = currentMembership?.role === TeamRole.OWNER || currentMembership?.role === TeamRole.ADMIN;

    return (
        <ListBase perPage={12}>
            <ListGridSwitcher
                actions={AssistantListActions}
                filters={AssistantFilters}
                ItemComponent={<AssistantInfoCard />}
            >
                <Datagrid
                    bulkActionButtons={
                        isOwnerOrAdmin ? (
                            <AssistantBulkActionButtons />
                        ) : false
                    }
                    rowClick={isOwnerOrAdmin ? 'edit' : false}
                >
                    <TextField source='name' />
                    <ReferenceField
                        source='group.id'
                        reference='assistant-groups'
                        sortBy='group__name'
                    >
                        <GroupField />
                    </ReferenceField>
                    <TextField source='status' />
                </Datagrid>
            </ListGridSwitcher>
        </ListBase>
    );
};

export default AssistantList;
