// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC, ReactNode } from 'react';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';

interface StackedNodesProps {
    children: ReactNode[] | ReactNode;
};

const StackedNodes: FC<StackedNodesProps> = ({
    children,
}) => {
    if (!children) {
        return null;
    }

    let childrenArray: ReactNode[] = [];
    if (!Array.isArray(children)) {
        childrenArray = [children];
    } else {
        childrenArray = children;
    }

    return (
        <Stack
            spacing={1}
        >
            <Grid container spacing={1}>
                {
                    childrenArray.map((child: ReactNode, index: React.Key | null | undefined) => (
                        <Grid key={index} size={{ xs: 12 }}>
                            {child}
                        </Grid>
                    ))
                }
            </Grid>
        </Stack>
    );
};

export default StackedNodes;
