// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import { ResourceProps } from 'react-admin';
import AssistantIcon from '@mui/icons-material/Assistant';

import assistantDataProvider from '@/assistant/assistantDataProvider';
import AssistantInterface from '@/assistant/AssistantInterface';
import { AssistantList } from '@/assistant/list';

const AssistantGroupResourceProps: ResourceProps = {
    name: 'assistant-groups',
};

const AssistantResourceProps: ResourceProps = {
    name: 'assistants',
    list: AssistantList,
    create: AssistantInterface,
    edit: AssistantInterface,
    icon: AssistantIcon,
};

export {
    assistantDataProvider,
    AssistantGroupResourceProps,
    AssistantResourceProps,
};
