// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC } from 'react';

import { ParameterRule } from '@/types';
import { ConfigurableInput } from '@/common';

type ProviderSetupFormProps = {
    credentialFormSchemas: ParameterRule[];
};

const ProviderSetupForm: FC<ProviderSetupFormProps> = ({
    credentialFormSchemas,
}) => (
    credentialFormSchemas.map((rule: ParameterRule) => (
        <ConfigurableInput
            key={rule.name}
            parameterRule={{...rule, name: `credentials.${rule.name}`}}
        />
    ))
);

export default ProviderSetupForm;
