// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC } from 'react';
import IconButton from '@mui/material/IconButton';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import GradeIcon from '@mui/icons-material/Grade';
import {
    useRecordContext, useDataProvider,
    useResourceContext, useRefresh,
} from 'react-admin';

import { Prompt, Assistant } from '@/types';
import { WithTooltip } from '@/common/layout';

interface PinButtonProps {
    disabled?: boolean;
};

const PinButton: FC<PinButtonProps> = ({
    disabled,
}) => {
    const dataProvider = useDataProvider();
    const record = useRecordContext<Prompt|Assistant>();
    const resource = useResourceContext();
    const refresh = useRefresh();

    if (!record || !resource) {
        return null;
    }

    const Pin = async (): Promise<void> => {
        await dataProvider.update(
            resource, {
                id: record.id,
                data: {
                    is_pinned: !record.is_pinned,
                },
                previousData: record,
            }
        ).then(() => {
            refresh();
        });
    };

    return (
        <WithTooltip
            title={record.is_pinned ? 'action.unpin' : 'action.pin'}
            trigger={(
                <IconButton
                    onClick={Pin}
                    disabled={disabled}
                >
                    {
                        record.is_pinned ? (
                            <GradeIcon sx={{ color: '#ffeb00' }} />
                        ) : (
                            <StarOutlineIcon />
                        )
                    }
                </IconButton>
            )}
        />
    );
};

export default PinButton;
