// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import {
    ParameterRule, ParameterType, StringParameterRule
} from '@/types';

export const prefixRuleName = (prefix: string, rules: ParameterRule[]): ParameterRule[] => (
    rules.map((rule) => ({
        ...rule,
        name: `${prefix}.${rule.name}`,
    }))
);

export const extractConfigurableParameterValues = (
    parameterRules: ParameterRule[],
    values: { [key: string]: any },
): { [key: string]: any } | null => {
    const config: { [key: string]: any } = {};
    parameterRules.forEach((rule: ParameterRule) => {
        if (Object.keys(values).includes(rule.name)) {
            config[rule.name] = values[rule.name];
            delete values[rule.name];
            // if the parameter is a string and has options (select input) => we need to find the option,
            // which could be an object, and move it to the chunk_config object
            if (rule.type === ParameterType.STRING && (rule as StringParameterRule).options) {
                const option = (rule as StringParameterRule).options.find(
                    (opt: ParameterRule) => opt.name === config[rule.name]
                );
                if (option && Object.keys(values).includes(option.name)) {
                    config[option.name] = values[option.name];
                    delete values[option.name];
                }
            }
        }
    });
    return Object.keys(config).length > 0 ? config : null;
};
