// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import { ResourceProps } from 'react-admin';

import VectorStoreField from './VectorStoreField';
import VectorStoreSetup, { transformVectorStoreSetupData } from './VectorStoreSetup';

const VectorStoreResourceProps: ResourceProps = {
    name: 'vector-stores',
};

export {
    VectorStoreResourceProps, VectorStoreField,
    VectorStoreSetup, transformVectorStoreSetupData,
};
