// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import { ResourceProps } from 'react-admin';
import TerminalIcon from '@mui/icons-material/Terminal';

import PromptInterface from '@/prompt/PromptInterface';
import { PromptList } from '@/prompt/list';
import promptDataProvider from '@/prompt/promptDataProvider';

const PromptGroupResourceProps: ResourceProps = {
    name: 'prompt-groups',
};

const PromptResourceProps: ResourceProps = {
    name: 'prompts',
    list: PromptList,
    create: PromptInterface,
    edit: PromptInterface,
    icon: TerminalIcon,
};

export {
    PromptGroupResourceProps,
    PromptResourceProps,
    promptDataProvider,
};
