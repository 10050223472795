// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC } from 'react';
import {
    ReferenceInput,
} from 'react-admin';

import { CollectionData, VectorStoreEntity } from '@/types';

import VectorStoreForm from './VectorStoreForm';
import { extractConfigurableParameterValues } from '@/utils';

export const transformVectorStoreSetupData = (
    formValues: any,
    selectedVectorStore: VectorStoreEntity | undefined,
): CollectionData => {
    const {
        vector_store: vectorStore, ...rest
    } = formValues;
    let vectorStoreConfig = null;
    if (selectedVectorStore) {
        // all parameters, which are in the select vector store parameter rules,
        // are moved to the vector_store_config object
        vectorStoreConfig = extractConfigurableParameterValues(
            selectedVectorStore.parameter_rules,
            rest
        );
    }
    // edit mode
    const data: CollectionData = {
        vector_store: vectorStore,
    };
    if (vectorStoreConfig) data.vector_store_config = vectorStoreConfig;
    return data;
};

type VectorStoreSetupProps = object;

const VectorStoreSetup: FC<VectorStoreSetupProps> = () => (
    <ReferenceInput
        source='vector_store'
        reference='vector-stores'
        perPage={-1}
        fullWidth
    >
        <VectorStoreForm />
    </ReferenceInput>
);

export default VectorStoreSetup;
