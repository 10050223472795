// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import { DataProvider, RaRecord } from 'react-admin';

export interface UserDataProvider extends DataProvider {
    self: () => Promise<any>;
};

export interface Identity extends RaRecord {
    first_name: string;
}

export interface Team extends RaRecord {
    name: string;
    description: string;
    status: string;
    user_role: TeamRole;
};

export enum TeamRole {
    OWNER = 'owner',
    ADMIN = 'admin',
    ENGINEER = 'engineer',
    Member = 'member',
};

export const TeamRoleChoices = Object.values(TeamRole).map((value) => ({
    id: value,
    name: value,
}));

export interface Membership extends RaRecord {
    is_active: boolean;
    joined_date: number;
    role: string;
};
