// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC, useCallback } from 'react';
import {
    ReferenceInput, TextInput,
    AutocompleteInputProps, ReferenceInputProps,
    useLocale, RecordContextProvider,
} from 'react-admin';
import { useFormContext } from 'react-hook-form'

import { ModelPropertyKey, ModelWithProviderEntity } from '@/types';
import { AutocompleteInput } from '@/common';
import ModelField from './ModelField';

interface ModelSelectInputProps extends Omit<AutocompleteInputProps, 'source' | 'filter' | 'disabled'> {
    filter: ReferenceInputProps['filter'];
    disabled?: boolean;
    onChange?: AutocompleteInputProps['onChange'];
    source?: string;
};

const ModelSelectInput: FC<ModelSelectInputProps> = ({
    filter,
    disabled,
    onChange,
    source = 'related_model',
    ...rest
}) => {
    const { setValue } = useFormContext();
    const locale = useLocale();

    const renderStartAdornment = useCallback((record: any) => (
        <RecordContextProvider key={record.id} value={record}>
            <ModelField
                properties={[
                    ModelPropertyKey.MODE,
                    ModelPropertyKey.CONTEXT_SIZE,
                ]}
                showType={false}
                renderName={false}
            />
        </RecordContextProvider>
    ), []);

    const handleModelChange: AutocompleteInputProps['onChange'] = (
        value,
        record
    ) => {
        if (!record) {
            return;
        }
        setValue(`${source}.model_name`, (record as ModelWithProviderEntity).model);
        setValue(`${source}.provider_name`, (record as ModelWithProviderEntity).provider.provider);
        if (onChange) {
            onChange(value, record);
        }
    };

    return (
        <>
            <ReferenceInput
                source={`${source}.model_name`}
                reference='models'
                filter={filter}
                perPage={-1}
                disabled={disabled}
            >
                <AutocompleteInput
                    debounce={500}
                    optionText={(choice) => (
                        <RecordContextProvider key={choice.id} value={choice}>
                            <ModelField
                                properties={[
                                    ModelPropertyKey.MODE,
                                    ModelPropertyKey.CONTEXT_SIZE,
                                ]}
                                showType={false}
                            />
                        </RecordContextProvider>
                    )}
                    inputText={(choice) => choice?.label[locale]}
                    margin='none'
                    groupBy={(choice) => choice?.provider.label[locale]}
                    onChange={handleModelChange}
                    renderStartAdornment={renderStartAdornment}
                    {...rest}
                />
            </ReferenceInput>
            <TextInput
                type='hidden'
                source={`${source}.provider_name`}
                sx={{ display: 'none' }}
            />
        </>
    );
};

export default ModelSelectInput;
