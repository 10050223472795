// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC, useContext } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AttachmentIcon from '@mui/icons-material/Attachment';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {
    useItemFinalizeListener,
    useItemProgressListener,
    FILE_STATES,
    BatchItem,
} from '@rpldy/uploady';

import { SessionContext } from '@/context';
import { truncateText } from '@/utils';
import { WithTooltip, IconButton, CircularProgress } from '@/common';

type AttachmentProps = object;

const Attachment: FC<AttachmentProps> = () => {
    const { attachedFile, setAttachedFile } = useContext(SessionContext);

    if (!attachedFile) {
        return null;
    }

    const { completed } = useItemProgressListener(attachedFile.id) || { completed: 0 };

    useItemFinalizeListener((it: BatchItem) => {
        setAttachedFile({
            ...attachedFile,
            state: it.state,
        });
    }, attachedFile.id);

    const isSuccess = attachedFile.state === FILE_STATES.FINISHED;
    const isFinished = ![FILE_STATES.PENDING, FILE_STATES.UPLOADING].includes(
        attachedFile.state
    );

    const handleRemoveAttachment = (): void => {
        setAttachedFile(undefined);
    }

    return (
        <Box
            display='flex'
            alignItems='center'
            justifyContent='flex-start'
            flexGrow={1}
        >
            <AttachmentIcon fontSize='small' />
            <Box
                display='flex'
                flexDirection='row'
                alignItems='center'
                title={attachedFile.file.name}
            >
                <Typography
                    variant='caption'
                    sx={{
                        ml: 1, mr: 1,
                    }}
                >
                    {
                        `${truncateText(attachedFile.file.name, 17, 'characters')}`
                    }
                </Typography>
                <WithTooltip
                    title='ra.action.remove'
                    trigger={(
                        <IconButton
                            edge='start'
                            aria-label='ra.action.remove'
                            disabled={!attachedFile}
                            onClick={handleRemoveAttachment}
                            size='small'
                        >
                            <CloseIcon />
                        </IconButton>
                    )}
                    arrow
                />
                {!isFinished && completed !==0 && <CircularProgress value={completed} /> }
                {isSuccess && <CheckCircleOutlineIcon color='success' />}
            </Box>
        </Box>
    );
}

export default Attachment;
