// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC, useContext } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import DraftsIcon from '@mui/icons-material/Drafts';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import {
    useRecordContext, useCreatePath, Link,
    useDataProvider, useRedirect, useTranslate,
    DeleteWithConfirmButton, ReferenceField,
} from 'react-admin';
import { styled } from '@mui/material/styles';

import {
    Assistant, AssistantStatus,
    TeamRole,
} from '@/types';
import { GlobalContext } from '@/context';
import { truncateText } from '@/utils';
import { GroupField } from '@/group';
import { PinButton } from '@/common';

const StyledCard = styled(Card, {
    name: 'AssistantInfoCard',
    slot: 'root',
})(() => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
}));

const StyledCardHeader = styled(CardHeader, {
    name: 'AssistantInfoCard',
    slot: 'header',
})(() => ({
    padding: '8px 16px',
}));

const StyledCardContent = styled(CardContent, {
    name: 'AssistantInfoCard',
    slot: 'content',
})(() => ({
    flexGrow: 1,
    padding: '8px 16px',
}));

type SubHeaderProps = object;

const SubHeader: FC<SubHeaderProps> = () => {
    const assistant = useRecordContext<Assistant>();

    if (!assistant) {
        return null;
    }

    return (
        <Stack
            direction='row'
            spacing={1}
            mt={1}
        >
            <ReferenceField source='group.id' reference='assistant-groups'>
                <GroupField
                    showLabel={false}
                />
            </ReferenceField>
            <Tooltip title={assistant.status.toUpperCase()}>
                { assistant.status === AssistantStatus.DRAFTED ? (
                    <DraftsIcon color='warning' />
                ) : (
                    <PublishedWithChangesIcon color='success' />
                )}
            </Tooltip>
        </Stack>
    );
};

type AssistantInfoCardActionsProps = object;

const AssistantInfoCardActions: FC<AssistantInfoCardActionsProps> = () => {
    const assistant = useRecordContext<Assistant>();
    const { currentMembership } = useContext(GlobalContext);
    const dataProvider = useDataProvider();
    const redirect = useRedirect();
    const translate = useTranslate();
    const createPath = useCreatePath();

    if (!assistant) {
        return null;
    }

    const isOwnerOrAdmin = currentMembership?.role === TeamRole.OWNER || currentMembership?.role === TeamRole.ADMIN;

    const OnUseClick = (): void => {
        const data = {
            name: 'New Chat',
            type: 'assistant',
            mode: 'chat',
            related_assistant: assistant.id,
        };
        dataProvider.create('sessions', { data }).then((response) => {
            const { data: session } = response;
            redirect('show', 'sessions', session.id);
        });
    };

    return (
        <CardActions disableSpacing>
            <Link
                to={
                    isOwnerOrAdmin ? (
                        createPath({
                            resource: 'assistants',
                            id: assistant.id,
                            type: 'edit'
                        })) : ''
                }
                title={translate('ra.action.edit')}
            >
                <EditIcon
                    color={isOwnerOrAdmin ? 'primary' : 'disabled'}
                />
            </Link>
            <Tooltip title={translate('action.use')}>
                <IconButton
                    size='small'
                    onClick={OnUseClick}
                    color='primary'
                >
                    <SmartToyIcon />
                </IconButton>
            </Tooltip>
            <DeleteWithConfirmButton
                label=''
                mutationMode='pessimistic'
                size='small'
                sx={{
                    ml: 'auto',
                    minWidth: 'auto',
                }}
                title={translate('ra.action.delete')}
                disabled={!isOwnerOrAdmin}
            />
        </CardActions>
    );
};

type AssistantInfoCardProps = object;

const AssistantInfoCard: FC<AssistantInfoCardProps> = () => {
    const assistant = useRecordContext<Assistant>();
    const { currentMembership } = useContext(GlobalContext);

    if (!assistant) {
        return null;
    }

    const isOwnerOrAdmin = currentMembership?.role === TeamRole.OWNER || currentMembership?.role === TeamRole.ADMIN;

    return (
        <StyledCard>
            <StyledCardHeader
                avatar={(
                    <Avatar
                        src={assistant.avatar}
                        alt={assistant.name}
                        sx={{ width: 100, height: 100 }}
                    >
                        {!assistant.avatar && assistant.name[0]}
                    </Avatar>
                )}
                title={(
                    <Typography
                        variant='subtitle1'
                        gutterBottom
                        sx={{
                            fontWeight: 'bold',
                            lineHeight: 1.2,
                        }}
                    >
                        {assistant.name}
                    </Typography>
                )}
                subheader={<SubHeader />}
                action={(
                    <PinButton
                        disabled={!isOwnerOrAdmin}
                    />
                )}
                disableTypography
            />
            <StyledCardContent>
                <Tooltip title={assistant.description}>
                    <Typography
                        variant='subtitle2'
                        gutterBottom
                    >
                        {truncateText(assistant.description, 20)}
                    </Typography>
                </Tooltip>
            </StyledCardContent>
            <AssistantInfoCardActions />
        </StyledCard>
    );
};

export default AssistantInfoCard;
