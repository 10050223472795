// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC } from 'react';

import {
    ParameterRule,
} from '@/types';
import {
    ControlledAccordion, StackedNodes,
    ConfigurableInput, ConfigurableInputProps,
} from '@/common';

interface StackedConfigurableInputsProps {
    parameterRules: ParameterRule[],
    onChange?: ConfigurableInputProps['onChange'],
};

const StackedConfigurableInputs: FC<StackedConfigurableInputsProps> = ({
    parameterRules,
    onChange,
}) => {
    if (!parameterRules) {
        return null;
    }

    return (
        <StackedNodes>
            {
                parameterRules.map((rule: ParameterRule, index: React.Key | null | undefined) => (
                    <ConfigurableInput
                        key={index}
                        parameterRule={rule}
                        defaultValue={rule.default}
                        onChange={onChange}
                    />
                ))
            }
        </StackedNodes>
    )
}

interface ConfigurableInputsProps {
    parameterRules: ParameterRule[],
    onChange?: ConfigurableInputProps['onChange'],
    advancedSectionTitle?: string,
};

const ConfigurableInputs: FC<ConfigurableInputsProps> = ({
    parameterRules,
    onChange,
    advancedSectionTitle = 'Advanced',
}) => {
    if (!parameterRules) {
        return null;
    }

    const basicRules = parameterRules.filter(
        (parameterRule) => !parameterRule.advanced
    );

    const advancedParameterRules = parameterRules.filter(
        (parameterRule) => parameterRule.advanced
    );

    return (
        <>
            {
                basicRules.length > 0 && (
                    <StackedConfigurableInputs
                        parameterRules={basicRules}
                        onChange={onChange}
                    />
                )
            }
            {
                advancedParameterRules.length > 0 && (
                    <ControlledAccordion
                        panels={['advanced']}
                        titles={[advancedSectionTitle]}
                    >
                        <StackedConfigurableInputs
                            parameterRules={advancedParameterRules}
                            onChange={onChange}
                        />
                    </ControlledAccordion>
                )
            }
        </>
    );
};

export default ConfigurableInputs;
