// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import { Dispatch, SetStateAction, createContext } from 'react';

import {
    CollectionConfiguration, VectorStoreEntity,
} from '@/types';

interface CollectionContextInterface {
    configuration: CollectionConfiguration | undefined;
    activeStep: number;
    setActiveStep: Dispatch<SetStateAction<number>>
    mode: 'create' | 'edit';
    setMode: Dispatch<SetStateAction<'create' | 'edit'>>;
    selectedVectorStore: VectorStoreEntity | undefined;
    setVectorStore: (vectorStore: VectorStoreEntity | undefined) => void;
    remainingFiles: number;
    setRemainingFiles: Dispatch<SetStateAction<number>>;
};

export const CollectionContext = createContext<CollectionContextInterface>({
    configuration: undefined,
    activeStep: 0,
    setActiveStep: () => {},
    selectedVectorStore: undefined,
    setVectorStore: () => {},
    mode: 'create',
    setMode: () => {},
    remainingFiles: 0,
    setRemainingFiles: () => {},
});
