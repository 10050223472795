// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import styled from '@mui/material/styles/styled';
import { useLocale, useTranslate, useRecordContext } from 'react-admin';

import { ModelPropertyKey, ProviderModelEntity } from '@/types';
import { formatBytes } from '@/utils';

const StyledChip = styled(Chip,{
    name: 'ModelField',
    slot: 'Chip',
})(() => ({
    borderRadius: 0,
    margin: '0 3px',
    fontSize: '0.6rem',
    '& .MuiChip-labelSmall': {
        padding: '0 2px',
    }
}));

interface ModelPropertyProps {
    property: ModelPropertyKey;
    value: any;
}

const ModelPropertyItem: FC<ModelPropertyProps> = ({
    property,
    value,
}) => {
    let label = '';
    switch (property) {
        case ModelPropertyKey.CONTEXT_SIZE:
        case ModelPropertyKey.MAX_CHUNKS:
        case ModelPropertyKey.FILE_UPLOAD_LIMIT:
            label = formatBytes(value);
            break;
        default:
            label = value.toUpperCase();
    };

    return (
        <Tooltip key={property} title={property}>
            <StyledChip size='small' label={label} sx={{ fontSize: '11px' }} />
        </Tooltip>
    )
};

interface ModelFieldProps {
    showType?: boolean;
    properties?: ModelPropertyKey[];
    renderName?: boolean;
}

const ModelField: FC<ModelFieldProps> = ({
    showType=true,
    properties,
    renderName=true,
}) => {
    const record = useRecordContext<ProviderModelEntity>();
    if (!record) {
        return null;
    }
    const locale = useLocale();
    const translate = useTranslate();

    return (
        <span>
            {
                renderName && record.label[locale]
            }
            {
                showType && (
                    <Tooltip title={translate('resources.models.fields.model_type')}>
                        <StyledChip size='small' label={record.model_type.toUpperCase()} />
                    </Tooltip>
                )
            }
            {
                Object.keys(record.model_properties).map((key: string) => {
                    if (properties && !properties.includes(key as ModelPropertyKey)) {
                        return null;
                    }
                    return (
                        <ModelPropertyItem
                            key={key}
                            property={key as ModelPropertyKey}
                            value={record.model_properties[key as ModelPropertyKey]}
                        />
                    );
                })
            }
        </span>
    )
};

export default ModelField;
