// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC } from 'react';
import EmailIcon from '@mui/icons-material/Email';
import InputAdornment from '@mui/material/InputAdornment';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import RsvpIcon from '@mui/icons-material/Rsvp';
import SendIcon from '@mui/icons-material/Send';
import {
    SaveButton, useTranslate, Button, useNotify,
    CreateBase, Form, TextInput, useRecordContext,
    SelectInput, required, useRefresh,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';

import { ButtonWithDialog } from '@/common';
import { Team, TeamRole, MetaParams } from '@/types';
import { getChoicesFromEnum } from '@/utils';

const InvitationCreateButtonView: FC = () => {
    const translate = useTranslate();
    const notify = useNotify();
    const { reset } = useFormContext();
    const refresh = useRefresh();

    const title = translate('label.membership.invite');
    const label = 'action.invite';

    return (
        <ButtonWithDialog
            label={label}
            startIcon={<RsvpIcon />}
            dialog={{
                title,
                maxWidth: 'xs',
                fullWidth: true,
                disableBackdropClick: true,
                ContentProps: {
                    dividers: true,
                },
                dialogContent: (
                    <>
                        <TextInput
                            source='email'
                            type='email'
                            variant='outlined'
                            InputProps={{
                                startAdornment: <InputAdornment position='start'><EmailIcon /></InputAdornment>,
                            }}
                            validate={required()}
                            fullWidth
                        />
                        <SelectInput
                            source='role'
                            choices={getChoicesFromEnum(TeamRole)}
                            validate={required()}
                            variant='outlined'
                            fullWidth
                        />
                    </>
                ),
                dialogAction: (onClose) => (
                    <>
                        <Button
                            onClick={() => onClose && onClose({}, 'escapeKeyDown')}
                            label='ra.action.cancel'
                            startIcon={<ErrorOutlineIcon />}
                        />
                        <SaveButton
                            label={label}
                            icon={<SendIcon />}
                            type='button'
                            mutationOptions={{
                                onSuccess: () => {
                                    notify('message.invited');
                                    reset();
                                    refresh();
                                    if (onClose) {
                                        onClose({}, 'escapeKeyDown');
                                    }
                                }
                            }}
                        />
                    </>
                ),
            }}
        />
    );
};

type InvitationCreateButtonProps = object

const InvitationCreateButton: FC<InvitationCreateButtonProps> = () => {
    const team = useRecordContext<Team>();

    if (!team) {
        return null;
    }

    const meta: MetaParams = {
        queryParams: {
            team: team.id.toString(),
        },
    };

    return (
        <CreateBase
            resource='invitations'
            mutationOptions={{ meta: meta as any }}
        >
            <Form>
                <InvitationCreateButtonView />
            </Form>
        </CreateBase>
    );
};

export default InvitationCreateButton;
