// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC, useEffect, useState } from 'react';
import {
    useTranslate, useDataProvider, Labeled,
} from 'react-admin';
import { useWatch } from 'react-hook-form';

import { ConfigurableInputs } from '@/common';
import { ParameterRule } from '@/types';

interface ModelParametersFormProps {
    modelInputName?: string;
    source?: string;
};

const ModelParametersForm: FC<ModelParametersFormProps> = ({
    modelInputName = 'related_model.model_name',
    source = 'related_model.config.parameters',
}) => {
    const modelName = useWatch({ name: modelInputName });
    const [rules, setRules] = useState<ParameterRule[]>([]);
    const translate = useTranslate();
    const dataProvider = useDataProvider();

    useEffect(() => {
        const fetchRules = async (): Promise<void> => {
            const convertedRules = await dataProvider.getParameterRules('models', {
                id: modelName,
            }).then((parametersData: any) => {
                const { data: parameterRules } = parametersData;
                return parameterRules.map((rule: ParameterRule) => {
                    const inputName = `${source}.${rule.name}`;
                    return {
                        ...rule,
                        name: inputName,
                    };
                });
            });
            setRules(convertedRules);
        }
        if (modelName) {
            fetchRules();
        }
    }, [modelName]);

    if (!modelName) {
        return null;
    }

    if (rules.length === 0) {
        return null;
    }

    return (
        <Labeled source='related_model.config.parameters'>
            <ConfigurableInputs
                parameterRules={rules}
                advancedSectionTitle={translate('label.advanced')}
            />
        </Labeled>
    );
};

export default ModelParametersForm;
