// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC, useContext } from 'react';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {
    RecordContextProvider
} from 'react-admin';

import { SessionContext } from '@/context';
import { useChatHandler } from '@/hook';
import { PagePlaceholder } from '@/common';
import AssistantField from '@/assistant/show/AssistantField';

type AssistantStartingMessageProps = object;

const AssistantStartingMessage: FC<AssistantStartingMessageProps> = () => {
    const { assistant } = useContext(SessionContext);
    const { handleSendMessage } = useChatHandler();

    if (!assistant) {
        return null;
    }

    return (
        <PagePlaceholder>
            <Box
                maxWidth={600}
                sx={{m: 2}}
            >
                <RecordContextProvider value={assistant}>
                    <AssistantField />
                </RecordContextProvider>
                <Typography
                    variant='subtitle1'
                    component='p'
                    gutterBottom
                    sx={{p: 1}}
                >
                    {assistant.opening_statement}
                </Typography>
                {
                    assistant.suggested_questions.map((question, index) => (
                        <IconButton
                            key={index}
                            onClick={() => handleSendMessage(question.question)}
                        >
                            <Paper
                                elevation={2}
                            >
                                <Typography
                                    variant='subtitle1'
                                    component='p'
                                    gutterBottom
                                    sx={{pl: 1, pr: 1}}
                                >
                                    {question.question}
                                </Typography>
                            </Paper>
                        </IconButton>
                    ))
                }
            </Box>
        </PagePlaceholder>
    );
};

export default AssistantStartingMessage;
