// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC } from 'react';
import {
    required, TextInput, ArrayInput,
    SimpleFormIterator,
} from 'react-admin';

type AssistantConfigurationFormProps = object;

const AssistantConfigurationForm: FC<AssistantConfigurationFormProps> = () => (
    <>
        <TextInput
            source='opening_statement'
            variant='outlined'
            validate={required()}
            multiline
            rows={4}
        />
        <ArrayInput
            source='suggested_questions'
            validate={required()}

        >
            <SimpleFormIterator
                inline
            >
                <TextInput
                    source='question'
                    variant='outlined'
                    validate={required()}
                />
            </SimpleFormIterator>
        </ArrayInput>
    </>
)

export default AssistantConfigurationForm;
