// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import { ResourceProps } from 'react-admin';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';

import CollectionList from './CollectionList';
import CollectionInterface from './CollectionInterface';
import collectionDataProvider from './collectionDataProvider';

const CollectionResourceProps: ResourceProps = {
    name: 'collections',
    list: CollectionList,
    create: CollectionInterface,
    edit: CollectionInterface,
    icon: LocalLibraryIcon,
};

export {
    CollectionResourceProps,
    collectionDataProvider,
};
