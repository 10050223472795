// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC } from 'react';
import Box from '@mui/material/Box';
import { Title } from 'react-admin';

import { SessionCreate } from '@/session';
import { ChatLayout } from '@/layout';

import NewChatPlaceholder from './NewChatPlaceholder';

type NewChatProps = object;

const NewChat: FC<NewChatProps> = () => (
    <ChatLayout>
        <Title title='New Chat' />
        <Box
            height='100%'
        >
            <Box margin='20px' height='20%'>
                <SessionCreate />
            </Box>
            <NewChatPlaceholder />
        </Box>
    </ChatLayout>

);

export default NewChat;
