// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC, useContext } from 'react';
import Box from '@mui/material/Box';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import {
    TopToolbar, SaveButton, Button,
    useRedirect, ButtonProps,
} from 'react-admin';

import { CancelButton } from '@/common';
import { AssistantContext } from '@/context';

type AssistantFormActionsProps = object;

const AssistantFormActions: FC<AssistantFormActionsProps> = () => {
    const redirect = useRedirect();

    const defaultButtonProps: ButtonProps = {
        variant: 'outlined',
        size: 'medium',
    };

    const {
        remainingFiles, activeStep, setActiveStep, mode,
    } = useContext(AssistantContext);

    const goNext = (): void => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const goBack = (): void => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }

    return (
        <TopToolbar
            sx={{
                justifyContent: 'space-between',
            }}
        >
            <Box>
                <Button
                    label='action.back'
                    startIcon={<ArrowBackIcon />}
                    onClick={() => goBack()}
                    sx={{ mr: 2 }}
                    disabled={activeStep === 0}
                    {...defaultButtonProps}
                />
                <CancelButton
                    onClick={() => redirect('list', 'assistants')}
                    {...defaultButtonProps}
                />
            </Box>
            <SaveButton
                type='button'
                label={activeStep !== 2 ? 'action.next':'action.publish'}
                endIcon={activeStep !== 2 ? <ArrowForwardIcon />:undefined}
                icon={<DoneAllIcon />}
                mutationOptions={
                    mode === 'create' ? ({
                        onSuccess: (data) => {
                            redirect('edit', 'assistants', data.id, data);
                            goNext();
                        }
                    }) : ({
                        meta: {
                            queryParams: {
                                publish: activeStep === 2,
                            },
                        },
                        onSuccess: () => {
                            if (activeStep < 2) {
                                goNext();
                            } else {
                                redirect('list', 'assistants');
                            }
                        }
                    })
                }
                alwaysEnable={mode !== 'create' && remainingFiles === 0}
                {...defaultButtonProps}
            />
        </TopToolbar>
    );
};

export default AssistantFormActions;
