// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import { useRecordContext } from 'react-admin';

import { Assistant } from '@/types';

interface AssistantFieldProps {
    renderName?: boolean;
};

const AssistantField: FC<AssistantFieldProps> = ({
    renderName=true,
}) => {
    const record = useRecordContext<Assistant>();
    if (!record) {
        return null;
    }
    return (
        <Tooltip title={record.description}>
            <Stack
                direction='row'
                spacing={1}
                alignItems='center'
            >
                <Avatar
                    src={record.avatar}
                    alt={record.name}
                    sx={{ width: 30, height: 30 }}
                >
                    {!record.avatar && record.name[0]}
                </Avatar>
                {
                    renderName && (<span>{record.name}</span>)
                }
            </Stack>
        </Tooltip>
    );
};

export default AssistantField;
