// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC } from 'react';
import {
    required, TextInput,
    ArrayInput, SimpleFormIterator,
} from 'react-admin';

import { ImageInput } from '@/common';
import { ModelSelectInput } from '@/provider/model';
import { GroupSelectInput } from '@/group';

type PromptSetupFormProps = object

const PromptSetupForm: FC<PromptSetupFormProps> = () => (
    <>
        <ImageInput
            source='avatar'
            sx={{
                flexDirection: 'row',
                '& .RaImageField-image': {
                    width: '100px !important',
                    height: '50px !important',
                },
            }}
        />
        <GroupSelectInput
            source='group_id'
            reference='prompt-groups'
        />
        <TextInput
            source='name'
            variant='outlined'
            validate={required()}
        />
        <TextInput
            source='description'
            variant='outlined'
            validate={required()}
            multiline
            rows={2}
        />
        <TextInput
            source='pre_prompt'
            variant='outlined'
            validate={required()}
            multiline
            rows={4}
        />
        <ArrayInput
            source='suggested_questions'
        >
            <SimpleFormIterator
                inline
            >
                <TextInput
                    source='question'
                    variant='outlined'
                    validate={required()}
                />
            </SimpleFormIterator>
        </ArrayInput>
        <ModelSelectInput
            variant='outlined'
            validate={required()}
            filter={{ model_type: 'llm', valid_only: true }}
        />
    </>
);

export default PromptSetupForm;
