// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React from 'react';
import {
    Admin, Resource, useStore, localStorageStore,
    StoreContextProvider, CustomRoutes, Authenticated,
} from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { Route } from 'react-router-dom';

import { dataProvider } from '@/dataProvider';
import authProvider from '@/authProvider';
import englishMessages from '@/i18n/en_US';

import { Dashboard } from '@/dashboard';
import { AssistantGroupResourceProps, AssistantResourceProps } from '@/assistant';
import { NewChat } from '@/chat';
import { CollectionResourceProps } from '@/collection';
import { VectorStoreResourceProps } from '@/collection/vectorStore';
import { Layout } from '@/layout';
import { PromptGroupResourceProps, PromptResourceProps } from '@/prompt';
import { TeamResourceProps } from '@/team';
import { MembershipResourceProps } from '@/team/membership';
import { InvitationResourceProps } from '@/team/invitation';
import { themes, Theme, ThemeName } from '@/themes';
import { SessionResourceProps } from '@/session';

const i18nProvider = polyglotI18nProvider(
    (locale: string) => {
        if (locale === 'de_DE') {
            return import('@/i18n/de_DE').then((messages) => messages.default);
        }
        // if (locale === 'fr_FR') {
        //     return import('@/i18n/fr_FR').then((messages) => messages.default);
        // }

        // Always fallback on english
        return englishMessages;
    },
    'en_US',
    [
        { locale: 'en_US', name: 'English' },
        { locale: 'de_DE', name: 'Deutsche' },
        // { locale: 'fr_FR', name: 'Français' },
    ],
);

const store = localStorageStore(undefined, 'GenFlow');

const App = (): JSX.Element => {
    const [themeName] = useStore<ThemeName>('themeName', 'soft');
    const lightTheme = themes.find((theme: Theme) => theme.name === themeName)?.light;
    const darkTheme = themes.find((theme: Theme) => theme.name === themeName)?.dark;

    return (
        <Admin
            store={store}
            i18nProvider={i18nProvider}
            layout={Layout}
            lightTheme={lightTheme}
            darkTheme={darkTheme}
            defaultTheme='light'
            dataProvider={dataProvider}
            authProvider={authProvider}
            dashboard={Dashboard}
            disableTelemetry
        >
            <Resource {...AssistantResourceProps} />
            <Resource {...AssistantGroupResourceProps} />
            <Resource {...VectorStoreResourceProps} />
            <Resource {...CollectionResourceProps} />
            <Resource {...PromptGroupResourceProps} />
            <Resource {...PromptResourceProps} />
            <Resource {...SessionResourceProps} />
            <Resource {...TeamResourceProps} />
            <Resource {...MembershipResourceProps} />
            <Resource {...InvitationResourceProps} />
            <CustomRoutes>
                <Route
                    path='/new'
                    element={(
                        <Authenticated>
                            <NewChat />
                        </Authenticated>
                    )}
                />
            </CustomRoutes>
        </Admin>
    );
};

const AppWrapper = (): JSX.Element => (
    <StoreContextProvider value={store}>
        <App />
    </StoreContextProvider>
);

export default AppWrapper;
