// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC,useMemo, useContext } from 'react';
import { useRecordContext } from 'react-admin';
import {
    UPLOADER_EVENTS, Batch,
    BatchItem, FILE_STATES,
} from '@rpldy/uploady';

import { Collection } from '@/types';
import { CollectionContext } from '@/context';
import { UploadArea } from '@/common';
import { ResourceURL } from '@/utils';
import { createOptions } from '@/authProvider';

type CollectionUploadProps = object;

const CollectionUpload: FC<CollectionUploadProps> = () => {
    const { setRemainingFiles } = useContext(CollectionContext);
    const listeners = useMemo(() => ({
        [UPLOADER_EVENTS.BATCH_ADD]: (batch: Batch) => {
            setRemainingFiles((prevItemCount) => (prevItemCount + batch.items.length));
        },
        [UPLOADER_EVENTS.ITEM_FINALIZE]: (item: BatchItem) => {
            switch (item.state) {
                case FILE_STATES.FINISHED:
                    setRemainingFiles((prevItemCount) => (prevItemCount - 1));
                    break;
                case FILE_STATES.ABORTED:
                    setRemainingFiles((prevItemCount) => (prevItemCount - 1));
                    break;
                default:
                    break;
            }
        },
    }), []);

    const record = useRecordContext<Collection>()
    if (!record) {
        return null;
    }

    const url = ResourceURL(`/collections/${record.id}/upload`)
    const options = createOptions(url);
    if (!options.user || !options.headers) {
        return null;
    }
    options.headers.set('authorization', options.user.token);
    const headersRecord: Record<string, string> = {};
    options.headers.forEach((value, key) => {
        headersRecord[key] = value;
    });

    return (
        <UploadArea
            autoUpload={false}
            destination={{
                url,
                headers: headersRecord,
            }}
            dropZoneOptions={{
                accept: {
                    'text/plain': ['.txt'],
                    'text/html': ['.html', '.htm'],
                    'application/pdf': ['.pdf']
                },
                multiple: true,
            }}
            listeners={listeners}
        />
    );
};

export default CollectionUpload;
