// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC, useContext } from 'react';
import Box from '@mui/material/Box';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import {
    TopToolbar, SaveButton, Button,
    useRedirect, SaveButtonProps, ButtonProps,
} from 'react-admin';
import { useFormState } from 'react-hook-form';

import { CancelButton } from '@/common';
import { CollectionContext } from '@/context';
import { transformVectorStoreSetupData } from '@/collection/vectorStore';
import { transformCollectionSetupData } from './CollectionSetup';

type CollectionFormActionsProps = object;

const CollectionFormActions: FC<CollectionFormActionsProps> = () => {
    const redirect = useRedirect();
    const { dirtyFields } = useFormState();

    const defaultButtonProps: ButtonProps = {
        variant: 'outlined',
        size: 'medium',
    };

    const {
        configuration, remainingFiles, selectedVectorStore,
        activeStep, setActiveStep, mode,
    } = useContext(CollectionContext);

    if (!configuration) {
        return null;
    }

    const goNext = (): void => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const goBack = (): void => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }

    const transform: SaveButtonProps['transform'] = (values: any) => {
        switch(activeStep) {
            case 0:
                return transformCollectionSetupData(
                    values,
                    mode,
                    dirtyFields
                );
            case 1:
                return transformVectorStoreSetupData(
                    values,
                    selectedVectorStore
                );
            default:
                return {};
        }
    };

    return (
        <TopToolbar
            sx={{
                justifyContent: 'space-between',
            }}
        >
            <Box>
                <Button
                    label='action.back'
                    startIcon={<ArrowBackIcon />}
                    onClick={() => goBack()}
                    sx={{ mr: 2 }}
                    disabled={activeStep === 0}
                    {...defaultButtonProps}
                />
                <CancelButton
                    onClick={() => redirect('list', 'collections')}
                    {...defaultButtonProps}
                />
            </Box>
            {
                activeStep !== 2 ? (
                    <SaveButton
                        type='button'
                        label='action.next'
                        endIcon={<ArrowForwardIcon />}
                        mutationOptions={
                            mode === 'create' ? ({
                                onSuccess: (data) => {
                                    redirect('edit', 'collections', data.id, data);
                                    goNext();
                                }
                            }) : ({
                                onSuccess: () => {
                                    goNext();
                                }
                            })
                        }
                        transform={transform}
                        alwaysEnable={mode !== 'create'}
                        {...defaultButtonProps}
                    />
                ) : (
                    <SaveButton
                        type='button'
                        label='action.finish'
                        icon={<DoneAllIcon />}
                        mutationOptions={{
                            meta: {
                                queryParams: {
                                    index: true,
                                },
                            },
                            onSuccess: () => {
                                redirect('list', 'collections');
                            }
                        }}
                        transform={transform}
                        alwaysEnable={remainingFiles === 0}
                        {...defaultButtonProps}
                    />
                )
            }
        </TopToolbar>
    );
};

export default CollectionFormActions;
