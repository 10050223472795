// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC,useMemo, useContext } from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { useRecordContext, useTranslate } from 'react-admin';
import {
    UPLOADER_EVENTS, Batch,
    BatchItem, FILE_STATES,
} from '@rpldy/uploady';

import { Assistant } from '@/types';
import { AssistantContext } from '@/context';
import { UploadArea, FileList } from '@/common';
import { ResourceURL } from '@/utils';
import { createOptions } from '@/authProvider';

type AssistantFilesUploadProps = object;

const AssistantFilesUpload: FC<AssistantFilesUploadProps> = () => {
    const record = useRecordContext<Assistant>()
    if (!record) {
        return null;
    }

    const translate = useTranslate();
    const { setRemainingFiles } = useContext(AssistantContext);
    const listeners = useMemo(() => ({
        [UPLOADER_EVENTS.BATCH_ADD]: (batch: Batch) => {
            setRemainingFiles((prevItemCount) => (prevItemCount + batch.items.length));
        },
        [UPLOADER_EVENTS.ITEM_FINALIZE]: (item: BatchItem) => {
            switch (item.state) {
                case FILE_STATES.FINISHED:
                    setRemainingFiles((prevItemCount) => (prevItemCount - 1));
                    break;
                case FILE_STATES.ABORTED:
                    setRemainingFiles((prevItemCount) => (prevItemCount - 1));
                    break;
                default:
                    break;
            }
        },
    }), []);

    const url = ResourceURL(`/assistants/${record.id}/upload`)
    const options = createOptions(url);
    if (!options.user || !options.headers) {
        return null;
    }
    options.headers.set('authorization', options.user.token);
    const headersRecord: Record<string, string> = {};
    options.headers.forEach((value, key) => {
        headersRecord[key] = value;
    });

    return (
        <>
            <UploadArea
                autoUpload={false}
                destination={{
                    url,
                    headers: headersRecord,
                }}
                dropZoneOptions={{
                    accept: {
                        'text/plain': ['.txt'],
                        'text/html': ['.html', '.htm'],
                        'application/pdf': ['.pdf']
                    },
                    multiple: true,
                }}
                listeners={listeners}
            />
            <Divider sx={{m: 1}} />
            <Typography
                variant='subtitle1'
                component='span'
            >
                {translate('label.assistant.interface.files')}
            </Typography>
            <FileList items={record.files} />
        </>
    );
};

export default AssistantFilesUpload;
