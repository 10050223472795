// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, {
    FC, useContext, useEffect, useCallback,
} from 'react';
import {
    SelectInput, required, RecordContextProvider,
    useChoicesContext, useRecordContext,
} from 'react-admin';

import { CollectionContext } from '@/context';
import {
    VectorStoreEntity, Collection,
} from '@/types';
import { ConfigurableInputs } from '@/common';
import VectorStoreField from './VectorStoreField';

type VectorStoreFormProps = object;

const VectorStoreForm: FC<VectorStoreFormProps> = () => {
    const { selectedChoices } = useChoicesContext();
    const { selectedVectorStore, setVectorStore } = useContext(CollectionContext);
    const record = useRecordContext<Collection>();

    useEffect(() => {
        if (selectedChoices && selectedChoices[0] && selectedChoices[0] !== selectedVectorStore) {
            setVectorStore(
                selectedChoices[0] as unknown as VectorStoreEntity
            );
        }
    }, [selectedChoices]);

    const renderOptionText = useCallback(
        (choice: VectorStoreEntity) => (
            <RecordContextProvider key={choice.id} value={choice}>
                <VectorStoreField />
            </RecordContextProvider>
        ),
        []
    );

    return (
        <>
            <SelectInput
                optionText={renderOptionText}
                variant='outlined'
                validate={required()}
                disabled={!!(record && record.status !== 'new')}
            />
            {
                (record && record.status !== 'new') ? (
                    null
                ) : (
                    selectedVectorStore && (
                        <ConfigurableInputs
                            parameterRules={selectedVectorStore.parameter_rules}
                        />
                    )
                )
            }
        </>
    );
}

export default VectorStoreForm;
