// Copyright (C) 2025 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, {
    FC, useContext, useCallback,
    createElement, SyntheticEvent,
} from 'react';
import {
    useRecordContext, Form,
    AutocompleteInputProps,
} from 'react-admin';

import { Session, PromptStatus } from '@/types';
import { SessionContext } from '@/context';
import { WithTooltip, IconButton } from '@/common';
import { PromptResourceProps } from '@/prompt';
import { PromptSelectInput } from '@/prompt/form';

interface PromptSelectionButtonProps {
    onClick?: (e: SyntheticEvent<HTMLElement>) => void;
};

export const PromptSelectionButton: FC<PromptSelectionButtonProps> = ({
    onClick,
}) => {
    const session = useRecordContext<Session>();
    if (!session || session.type !== 'llm') return null;

    const {
        setShowPromptInput,
    } = useContext(SessionContext);

    const handleClick = useCallback((e: SyntheticEvent<HTMLElement>) => {
        setShowPromptInput((prev) => !prev);
        onClick?.(e);
    }, []);

    return (
        <WithTooltip
            title='label.chat.input.use_prompt'
            trigger={(
                <span>
                    <IconButton
                        edge='start'
                        aria-label='label.chat.input.use_prompt'
                        disabled={!session}
                        onClick={handleClick}
                        size='small'
                    >
                        {
                            PromptResourceProps.icon ? createElement(PromptResourceProps.icon, { fontSize: 'small'}) : false
                        }
                    </IconButton>
                </span>
            )}
            arrow
        />
    );
};

type PromptSelectionProps = object;

const PromptSelection: FC<PromptSelectionProps> = () => {
    const session = useRecordContext<Session>();

    if (!session || session.type !== 'llm') return null;

    const {
        showPromptInput, setUserInput,
    } = useContext(SessionContext);

    if (!showPromptInput) return null;

    const onPromptChange: AutocompleteInputProps['onChange'] = (
        value,
        record
    ) => {
        if (!record) {
            return;
        }
        setUserInput(record.pre_prompt);
    };

    return (
        <Form>
            <PromptSelectInput
                source='prompt'
                filter={{ status: PromptStatus.PUBLISHED }}
                sort={{ field: 'group__name', order: 'ASC' }}
                onChange={onPromptChange}
            />
        </Form>
    );
};

export default PromptSelection;
